import React from 'react';
import docsImg from '../images/document.png';
import vdoImg from '../images/vdo.png';
import { HEAD_TEST_TEXT_MULTISEND, HEAD_TEXT_MULTISEND, IS_MAINNET, MAINNET_LINK, MULTISENDER_CHAIN, SUPPORTED_CHAIN, TESTNET_LINK, contract } from '../helper/constant';

export default function MultisenderHeadSection({ settingStats }) {

    return (
        <div className='row d-flex justify-content-center align-items-center py-4'>
            <div className='col-12 col-md-8 text-center'>
                <h3 className='text-white mt-2 mt-md-0 mb-md-0 text-bold text-uppercase'>TOKEN / NFT SENDER</h3>
                <p className='text-success text-bold mb-1 mb-md-0 text-lg'>Transfer 10,000 address in 5 minutes</p>
                <p className='text-white text-bold mb-1 mb-md-2 text-lg'>{IS_MAINNET ?   HEAD_TEST_TEXT_MULTISEND : HEAD_TEXT_MULTISEND} <a href={`${IS_MAINNET ? TESTNET_LINK : MAINNET_LINK}multisender`} target='_blank' className='text-warning'>click here</a></p>
                <div className='d-flex justify-content-center mt-0 mt-md-0'>
                    {contract && MULTISENDER_CHAIN && MULTISENDER_CHAIN.map((item, key) => {
                        return (
                            contract[item] &&
                            <div className='mx-2 border border-radius-xl p-2'>
                                <img src={contract[item].img} height="20px" width="20px" className='mx-1' alt="chainimg" />
                                <span className='text-bold'>{contract[item].name}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
