import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { DEFAULT_CHAIN, MULTISENDER_CHAIN, contract, getWeb3 } from '../helper/constant';
import { useNetwork } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import { updateTxHash } from '../redux/slices/MultisenderSlice';



export default function Transactions({ index }) {
    const { chain } = useNetwork();
    const [txStatus, setTxStatus] = useState(2)
    const txhash = useSelector(state => state.multisender.txhash);
    const dispatch = useDispatch();

    useEffect(() => {
        function fetch() {
            let web3 = getWeb3(chain.id);
            
            if (txhash[index].hash && txhash[index].hash !== '') {
                var interval = setInterval(async function () {

                    var response = await web3.eth.getTransactionReceipt(txhash[index].hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            setTxStatus(1)
                            dispatch(updateTxHash({ id : index ,hash:txhash[index].hash, status: 1 }))
                        }
                        else if (response.status === false) {
                            dispatch(updateTxHash({ id : index ,hash:txhash[index].hash, status: 3 }))
                            setTxStatus(3)
                        }
                        else {
                            dispatch(updateTxHash({ id : index ,hash:txhash[index].hash, status: 3 }))
                            setTxStatus(3)
                        }
                    }
                }, 5000);
            }   
        }

        fetch()
        // eslint-disable-next-line 
    }, [txhash[index].hash])


    return (

        // console.log(txhash[index]);

        <article className={`media notification is-white ${txStatus === 1 ? 'text-green-800 bg-green-100' : txStatus === 3 || txhash[index].status === 3 ? 'bg-red-100 text-red-800' : 'text-blue-800 bg-blue-100' } `}>
            <div className={`media-left ${txStatus === 1 ? 'text-green-800' : txStatus === 3 || txhash[index].status === 3 ? 'text-red-800' : 'text-blue-800' }`}>{index + 1}</div>
            <div className="media-content">
                <div className="content">
                    <a target="_blank" className={`${txStatus === 1 ? 'text-green-800' : txStatus === 3 || txhash[index].status === 3 ? 'text-red-800' : 'text-blue-800' }`} rel="noreferrer" href={`${contract[chain && chain.id && MULTISENDER_CHAIN.includes(chain.id) ? chain.id : DEFAULT_CHAIN].EXPLORE}tx/${txhash[index].hash ? txhash[index].hash : ''}`}>
                        {txhash[index].hash ? txhash[index].hash : txhash[index].error}
                    </a>
                </div>
            </div>
            <div className="media-right">
                <span>
                    <span className="icon has-text-success is-small">
                        {txStatus === 1 ? (
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                        ) : (
                            txStatus === 3 || txhash[index].status === 3? (
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="#FF0000" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                            ) : (
                                <Oval
                                    visible={true}
                                    height="24"
                                    width="24"
                                    strokeWidth={3}
                                    color="#4fa94d"
                                    ariaLabel="oval-loading"
                                    wrapperClass=""
                                />
                            )
                        )}

                    </span>
                </span>
            </div>
        </article>



    )
}