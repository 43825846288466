import React, { useEffect, useState } from 'react'
import { useAccount } from 'wagmi';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRefStats } from '../hooks/useState';


export default function MultisenderRef() {
    const { address } = useAccount();
    const [referrallink, setReferrallink] = useState(
        "Please connect your wallet"
    );
    const [refcopy, setRefcopy] = useState(false);
    let base_url = `${window.location.origin}/multisender?ref=`;
    const state = useRefStats(1);
  
    useEffect(() => {
        if (address) {
            setReferrallink(`${base_url}${address}`);
        } else {
            setReferrallink("Please connect your wallet");
        }
    }, [address]);


    return (
        <div className='row d-flex justify-content-center text-center mt-5 mb-5 mx-0'>
            <div className='bg-card border-dark col-12 mb-4 mb-md-0 px-md-5 py-4'>
                <h3 className='text-white text-bold mt-2 mt-md-0'>Earn bonus {state.mlevelFees ? state.mlevelFees : 0 }% from referral</h3>
                <p className='text-bold mt-2 mt-md-0 text-md font-color'>Receive to your wallet immediately</p>
                <div className='row d-flex justify-content-center'>
                    <div className='col-11 col-md-6'>
                        <div className='text-center'>
                            <div class="input-group" style={{ cursor: "pointer" }}>
                                <input type='text' value={referrallink} readOnly={true} className='form-control' name="referral" placeholder='Connect wallet' />
                                <CopyToClipboard text={referrallink} onCopy={() => {
                                    setRefcopy(true);
                                    setTimeout(() => {
                                        setRefcopy(false);
                                    }, 2000)
                                }}>
                                    {refcopy ? (
                                        <span class="input-group-text">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                                        </span>
                                    ) : (
                                        <span class="input-group-text">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        </span>
                                    )}

                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
