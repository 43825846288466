import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStep3Validation } from '../redux/slices/MultisenderSlice';
import { formatPrice } from '../helper/contractHelper';

export default function SenderStep3() {

  const dispatch = useDispatch();
  const tokenInfo = useSelector(state => state.multisender.tokenInfo)
  const ethInfo = useSelector(state => state.multisender.ethInfo)
  const nftInfo = useSelector(state => state.multisender.nftInfo)
  const tokenType = useSelector(state => state.multisender.tokenType)
  const tokenAddress = useSelector(state => state.multisender.tokenAddress)
  const totalTokenSent = useSelector(state => state.multisender.totalTokenSent);
  const numberofaddress = useSelector(state => state.multisender.numberofaddress);
  const numberoftransaction = useSelector(state => state.multisender.numberoftransaction);
  const step3validation = useSelector(state => state.multisender.step3validation);
  const cost = useSelector(state => state.multisender.cost);




  return (
    // <div className='bg-body-card max-w-3xl mt-1 mx-auto rounded-3 rounded-xl border-input'>
    //   <div className='p-0 p-xl-5'>
    <>

      <div class="grid gap-5">
        <div class="grid grid-cols-2 grid-cols-1 gap-3">
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">{numberofaddress}</span>
            <span class="text-sm text-muted-foreground">Total number of addresses</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">
              {
                tokenType === 3 ? formatPrice(numberofaddress, 4) : formatPrice(totalTokenSent, 6)} {" "}
              {tokenAddress && tokenType === 2 ? tokenInfo.symbol : tokenAddress && tokenType === 3 ? nftInfo.symbol : ethInfo.symbol}
            </span>
            <span class="text-sm text-muted-foreground">Total number of tokens to be sent</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">
              {tokenAddress && tokenType === 2 ? formatPrice(tokenInfo.balance) :
                tokenAddress && tokenType === 3 ? formatPrice(nftInfo.balance) : formatPrice(ethInfo.balance, 4)} {" "}
              {tokenAddress && tokenType === 2 ? tokenInfo.symbol : tokenAddress && tokenType === 3 ? nftInfo.symbol : ethInfo.symbol}
            </span>
            <span class="text-sm text-muted-foreground">Your token balance</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">{numberoftransaction}</span>
            <span class="text-sm text-muted-foreground">Total number of transactions needed</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">{ethInfo ? formatPrice(ethInfo.balance, 4) : ' - '} {ethInfo ? ethInfo.symbol : ' - '} </span>
            <span class="text-sm text-muted-foreground">Your {ethInfo ? ethInfo.symbol : ' - '} balance</span>
          </div>
          <div class="flex flex-col items-center justify-center gap-3 py-3 rounded-xl bg-body-dark rounded-3 border-input ">
            <span class="text-2xl break-all">{formatPrice(cost, 4)} {ethInfo ? ethInfo.symbol : ' - '}</span>
            <span class="text-sm text-muted-foreground">Approximate Cost of operation</span>
          </div>
        </div>
      </div>

      <button type="button" onClick={() => dispatch(fetchStep3Validation({}))} class="btn text-dark bg-warning w-full mt-3">
        {step3validation ? 'Loading...' : 'Continue'}
      </button>
      {/* </div>
    </div> */}
    </>
  )
}
