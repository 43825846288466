import React, { useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import Home from "./pages/Home";
import Sidebar from "./components/Sidebar";
import { BaseContext } from "./context/BaseProvider";
import Multisender from "./pages/Multisender";
import { useSettingsStats } from "./hooks/useState";
import Admin from "./pages/Admin";

function App() {
  const { sidebar } = useContext(BaseContext);
  const settingStats = useSettingsStats(1);

  return (
    <div className={`App g-sidenav-show ${sidebar ? 'g-sidenav-pinned' : ''}`}>
      <BrowserRouter>
        <ToastContainer pauseOnFocusLoss={false} />
        <Sidebar settingStats={settingStats} />
        <main class={`main-content position-relative border-radius-lg `}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/multisender" element={<Multisender />} />
            <Route exact path="/admin" element={<Admin />} />

          </Routes>
        </main>
        <Footer />

      </BrowserRouter>


    </div >
  );
}

export default App;
