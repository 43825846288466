import React, { useContext, useState, useEffect } from 'react';
import logoImg from '../images/logo.png'
import { BaseContext } from '../context/BaseProvider';
import Connect from './Connect';
import { DEFAULT_CHAIN, SUPPORTED_CHAIN, contract, getWeb3 } from '../helper/constant';
import { useNetwork } from 'wagmi';
import { ethers } from 'ethers';


export default function Header() {
  const { chain } = useNetwork();
  const { setSidebar, sidebar } = useContext(BaseContext);
  const [web3, setWeb3] = useState(null);

  useEffect(() => {
    // Check if MetaMask is installed and enabled
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setWeb3(provider);
    }
  }, []);

  const switchNetwork = async (network) => {
    if (!web3) {
      console.error('Web3 not initialized.');
      return;
    }

    try {
      await web3.send('wallet_switchEthereumChain', [{ chainId: '0x' + network.toString(16) }]);
      // Perform additional tasks if needed after switching the network
      console.log(`Switched to network ${network}`);
    } catch (error) {
      console.error('Error switching network:', error);
      // If the error indicates that the network is not available, try adding it
      if (error.code === 4902) {
        try {
          await web3.send('wallet_addEthereumChain', [
            {
              chainId: '0x' + network.toString(16),
              chainName: contract[network] ? contract[network].name : contract[DEFAULT_CHAIN].name, // Replace with your network name
              nativeCurrency: {
                name: contract[network] ? contract[network].symbol : contract[DEFAULT_CHAIN].symbol,
                symbol: contract[network] ? contract[network].symbol : contract[DEFAULT_CHAIN].name,
                decimals: 18,
              },
              rpcUrls: [contract[network] ? contract[network].rpc : contract[DEFAULT_CHAIN].rpc], // Replace with your RPC endpoint
            },
          ]);
          console.log(`Added network ${network}`);
          // Retry switching network after adding it
          await switchNetwork(network);
        } catch (addError) {
          console.error('Error adding network:', addError);
        }
      }
    }
  };

  return (
    <div className='border-bottom'>
      <nav class="navbar navbar-main navbar-expand-lg px-0 mx-0 mx-md-4 shadow-none mt-0 mt-md-3 " id="navbarBlur" data-scroll="false">
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <span type='button' onClick={() => setSidebar(!sidebar)} class="nav-link text-white menu-btn" id="iconNavbarSidenav">
              <svg className='bg-transparent' viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            </span>
          </nav>
          <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
            <ul class="ms-md-auto navbar-nav justify-content-end">
              <li class="nav-item d-flex align-items-center dropdown mx-3">
                <button class="bg-card btn btn-primary mb-0 me-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {chain && chain.id && SUPPORTED_CHAIN.includes(chain.id) ? (
                    <span data-value={contract[chain.id].symbol}>
                      <img class="crypto-icon" src={contract[chain.id].img} alt={contract[chain.id].symbol} />
                      <span className='mx-2'>{contract[chain.id].name}</span>
                    </span>
                  ) : (
                    <span data-value={contract[DEFAULT_CHAIN].symbol}>
                      <img class="crypto-icon" src={contract[DEFAULT_CHAIN].img} alt={contract[DEFAULT_CHAIN].symbol} />
                      {contract[DEFAULT_CHAIN].name}
                    </span>
                  )}
                </button>
                <ul class="dropdown-menu dropdown-menu-dark">
                  {SUPPORTED_CHAIN && SUPPORTED_CHAIN.map((row, key) => {
                    return (
                      contract[row] &&
                      <li key={key} onClick={() => switchNetwork(row)}>
                        <span class="dropdown-item" data-value={contract[row].symbol}>
                          <img class="crypto-icon" src={contract[row].img} alt={contract[row].symbol} />
                          {contract[row].name}
                        </span>
                      </li>
                    )
                  })}

                </ul>
              </li>

              <li class="nav-item align-items-center">
                <Connect />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className='my-2'>
        <coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,solana,usd-coin,tether,binancecoin,tron,chainlink,matic-network,uniswap,eos,ripple,litecoin" currency="usd" background-color="#ffffff" locale="en"></coingecko-coin-price-marquee-widget>
      </div>
    </div>
  )
}