import React from 'react';
import { Modal } from "react-bootstrap"

export default function ExampleModal({ show, setShow, isDrop }) {
    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className='example-title'>Examples</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isDrop ? (
                    <React.Fragment>
                        <div className="example">
                            <div className="erc20-title erc-padding">for ERC20 or ETH(address, amount)</div>
                            <div className="erc20-content">0x3df332e44a0bbff025838c187873d77f92caf5e9,0.001</div>
                            <div className="erc20-content">0x76d31966abf3edeb29e599eac4adcb72fba85e6a,1</div>
                            <div className="erc20-content">0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF,3.45</div>
                            <div className="erc721-title erc-padding">for ERC721(address, token id)</div>
                            <div className="erc20-content">0x3df332e44a0bbff025838c187873d77f92caf5e9,1</div>
                            <div className="erc20-content">0x76d31966abf3edeb29e599eac4adcb72fba85e6a,2</div>
                            <div className="erc20-content">0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF,3</div>
                        </div>
                        <div className="example-desc erc-padding">
                            Separated by commas
                        </div>

                    </React.Fragment>
                ) :
                    (
                        <React.Fragment>
                            <div className='example'>
                                <div class="media">
                                    <div class="media-content">
                                        <h5 style={{ "fontWeight": "bold" }}>ERC20 or ETH</h5>
                                        <a target="_blank" href="/example_erc20_excel.xlsx" class="text-link fix">
                                            <span class="icon is-small">
                                                <i class="mdi mdi-file-excel"></i>
                                            </span>Excel</a>
                                        <span>|&nbsp;&nbsp;
                                        </span>
                                        <a target="_blank" href="/example_erc20_csv.csv" class="text-link fix">
                                            <span class="icon is-small">
                                                <i class="mdi mdi-file-delimited"></i>
                                            </span>
                                            CSV
                                        </a>
                                        <span>|&nbsp;&nbsp;
                                        </span>
                                        <a target="_blank" href="/example_erc20_txt.txt" class="text-link fix">
                                            <span class="icon is-small">
                                                <i class="mdi mdi-note-text"></i>
                                            </span>
                                            Txt
                                        </a>
                                    </div>
                                </div>
                                <hr class="dropdown-divider" />
                                <div class="media">
                                    <div class="media-content">
                                        <h5 style={{ "fontWeight": "bold" }}>ERC721 (NFT)</h5>
                                        <a target="_blank" href="/example_erc721_excel.xlsx" class="text-link fix">
                                            <span class="icon is-small">
                                                <i class="mdi mdi-file-excel"></i>
                                            </span>
                                            Excel
                                        </a>
                                        <span>|&nbsp;&nbsp;
                                        </span>
                                        <a target="_blank" href="/example_erc721_csv.csv" class="text-link fix">
                                            <span class="icon is-small">
                                                <i class="mdi mdi-file-delimited"></i>
                                            </span>
                                            CSV
                                        </a>
                                        <span>|&nbsp;&nbsp;
                                        </span>
                                        <a target="_blank" href="/example_erc721_txt.txt" class="text-link fix">
                                            <span class="icon is-small">
                                                <i class="mdi mdi-note-text"></i>
                                            </span>
                                            Txt
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="example-desc erc-padding">
                                Accepted: CSV / Excel / Txt
                            </div>
                        </React.Fragment>
                    )}
            </Modal.Body>
        </Modal>
    )
}
