import React, { useState } from 'react'
import { DEFAULT_CHAIN, SUPPORTED_CHAIN, contract, getWeb3 } from '../helper/constant';
import { toast } from 'react-toastify';
import { getContract } from '../helper/contractHelper';
import { useAccount, useNetwork } from 'wagmi';
import { ethers } from 'ethers';
import { useEthersSigner } from '../hooks/useEthersProvider';
import serviceAbi from '../json/services.json';
import multisenderAbi from '../json/multisender.json';
import Header from '../components/Header';

export default function Admin() {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner()

    const [basic, setBaisc] = useState(0);
    const [standard, setStandard] = useState(0);
    const [premium, setPremium] = useState(0);
    const [c_adminwallet, setC_adminwallet] = useState('');
    const [c_refper, setC_refper] = useState(0);
    const [m_refper, setM_refper] = useState(0);
    const [m_adminwallet, setM_adminwallet] = useState('');
    const [senderFees, setSenderFees] = useState(0);
    const [c_owner, setC_owner] = useState('');
    const [m_owner, setM_owner] = useState('');


    const handleSubmit = async (id) => {
        if (address) {
            if (chain && chain.id && SUPPORTED_CHAIN.includes(chain.id)) {

                try {

                    let serviceContract = getContract(serviceAbi, contract[chain.id].MASTER_ADDRESS, signer);
                    let multisenderContract = getContract(multisenderAbi, contract[DEFAULT_CHAIN].MULTISENDER_ADDRESS, signer);
                    let tx;
                    if (id === 1) {
                        tx = await serviceContract.addServices('basic', ethers.utils.parseEther(basic.toString()), { 'from': address });
                    }
                    else if (id === 2) {

                        tx = await serviceContract.addServices('standard', ethers.utils.parseEther(standard.toString()), { 'from': address });
                    }
                    else if (id === 3) {
                        tx = await serviceContract.addServices('premium', ethers.utils.parseEther(premium.toString()), { 'from': address });
                    }
                    else if (id === 4) {
                        tx = await serviceContract.setAdminWallet(c_adminwallet, { 'from': address });

                    }
                    else if (id === 5) {
                        tx = await serviceContract.setLevelRate(c_refper * 100, { 'from': address });
                    }
                    else if (id === 6) {
                        tx = await multisenderContract.setAdminWallet(m_adminwallet, { 'from': address });
                    }
                    else if (id === 7) {
                        tx = await multisenderContract.setFee(ethers.utils.parseEther(senderFees.toString()), { 'from': address });
                    }
                    else if (id === 8) {
                        tx = await multisenderContract.setLevelRate(m_refper * 100, { 'from': address });
                    }
                    else if (id === 9) {
                        tx = await serviceContract.transferOwnership(c_owner, { 'from': address });
                    }
                    else if (id === 10) {
                        tx = await multisenderContract.transferOwnership(m_owner, { 'from': address });
                    }
                    else {
                        toast.error('something went wrong');
                        return false;
                    }
                    toast.loading('Waiting for confirmation.');
                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chain.id);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success');

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please switch network to supported chain');
            }

        } else {
            toast.dismiss()
            toast.error('Please connect wallet');
        }
    }



    return (
        <>
            <Header />
            <div className="container text-white" style={{ marginTop: "50px", marginBottom: "100px" }}>
                <table class="table text-white table-dark">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Function Name</th>
                            <th scope="col">Input</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Basic Fees</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter Fees" value={basic} onChange={(e) => setBaisc(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(1)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Standard Fees</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter Fees" value={standard} onChange={(e) => setStandard(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(2)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Premium Fees</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter fees" value={premium} onChange={(e) => setPremium(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(3)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Create Token Admin Wallet</td>
                            <td>
                                <input type="text" class="form-control" placeholder="admin wallet" value={c_adminwallet} onChange={(e) => setC_adminwallet(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(4)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Create Token Referral (%)</td>
                            <td>
                                <input type="text" class="form-control" placeholder="ref percentage" value={c_refper} onChange={(e) => setC_refper(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(5)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Multisender Admin wallet</td>
                            <td>
                                <input type="text" class="form-control" placeholder="admin wallet" value={m_adminwallet} onChange={(e) => setM_adminwallet(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(6)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Multisender Fees</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter fees" value={senderFees} onChange={(e) => setSenderFees(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(7)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Multisender Referral(%)</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter token address" value={m_refper} onChange={(e) => setM_refper(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(8)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Makecoin Contract Ownership Transfer</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter new owner address" value={c_owner} onChange={(e) => setC_owner(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(9)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Multisender Contract Ownership Transfer</td>
                            <td>
                                <input type="text" class="form-control" placeholder="Enter new owner address" value={m_owner} onChange={(e) => setM_owner(e.target.value)} />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit(10)}
                                >
                                    Submit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
