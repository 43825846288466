import React from "react";
import { useAccount } from 'wagmi';
import { useWeb3Modal, Web3Button } from '@web3modal/react';




export const Connect = function () {
    const { address, isConnected } = useAccount()
    const { open } = useWeb3Modal()


    return (
        <React.Fragment>

            <>
                {address && isConnected ? (

                    <Web3Button />

                ) : (
                    <button className='bg-warning btn btn-primary btn-sm mb-0 me-1 p-2 px-4 text-dark fs-14' onClick={() => open()}>
                        <i class="fa-solid fa-wallet mx-2"></i>
                        Connect
                    </button>
                )
                }

            </>

        </React.Fragment >
    );
};

export default Connect;