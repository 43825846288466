import React from 'react';
import '../style/multisender.css';
import { useSelector } from 'react-redux';

export default function MultisenderStep() {
    const currentStep = useSelector(state => state.multisender.step);


    return (
        <div class="w-full py-4">
            <div class="flex">
                <div class="w-1/3">
                    <div class="relative mb-2">
                        <div class="flex items-center justify-center mx-auto mb-2 text-lg text-white border-2 rounded-full border-primary w-7 h-7">
                            {currentStep >= 1 &&
                                <span class="w-4 h-4 text-white rounded-full bg-primary"></span>
                            }
                        </div>
                        <div class="text-xs text-center capitalize">prepare</div>
                    </div>
                </div>
                <div class="w-1/3">
                    <div class="relative mb-2">
                        <div class="absolute flex items-center content-center align-middle -translate-x-1/2 mt-2 top-3 align-center" style={{ "width": "calc(100% - 3.5rem)" }}>
                            <div class={`items-center flex-1 w-full align-middle ${currentStep > 1 ? 'bg-warning' : ' bg-accent' } rounded-xl align-center`}>
                                <div class="w-0 h-1 bg-primary rounded"></div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center mx-auto mb-2 text-lg text-white border-2 rounded-full border-primary w-7 h-7">
                            {currentStep >= 2 &&
                                <span class="w-4 h-4 text-white rounded-full bg-primary"></span>
                            }
                        </div>
                        <div class="text-xs text-center capitalize">approve</div>
                    </div>
                </div>
                <div class="w-1/3">
                    <div class="relative mb-2">
                        <div class="absolute flex items-center content-center align-middle -translate-x-1/2 mt-2 top-3 align-center" style={{ "width": "calc(100% - 3.5rem)" }}>
                        <div class={`items-center flex-1 w-full align-middle ${currentStep > 2 ? 'bg-warning' : ' bg-accent' } rounded-xl align-center`}>
                                <div class="w-0 h-1 bg-primary rounded"></div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center mx-auto mb-2 text-lg text-white border-2 rounded-full border-primary w-7 h-7">
                            {currentStep >= 3 &&
                                <span class="w-4 h-4 text-white rounded-full bg-primary"></span>
                            }
                        </div>
                        <div class="text-xs text-center capitalize">multisend</div>
                    </div>
                </div>
                <div class="w-1/3">
                    <div class="relative mb-2">
                        <div class="absolute flex items-center content-center align-middle -translate-x-1/2 mt-2 top-3 align-center" style={{ "width": "calc(100% - 3.5rem)" }}>
                        <div class={`items-center flex-1 w-full align-middle ${currentStep > 3 ? 'bg-warning' : ' bg-accent' } rounded-xl align-center`}>
                                <div class="w-0 h-1 bg-primary rounded"></div>
                            </div>
                        </div>
                        <div class="flex items-center justify-center mx-auto mb-2 text-lg text-white border-2 rounded-full border-primary w-7 h-7">
                            {currentStep >= 4 &&
                                <span class="w-4 h-4 text-white rounded-full bg-primary"></span>
                            }
                        </div>
                        <div class="text-xs text-center capitalize">transactions</div>
                    </div>
                </div>
            </div>
        </div>

    )
}
