import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header';
import HeadSection from '../components/HeadSection';
import CreateSection from '../components/CreateSection';
import DetailsSection from '../components/DetailsSection';
import LastTokens from '../components/LastTokens';
import LastReferral from '../components/LastReferral';
import CreatedSection from '../components/CreatedSection';
import { BaseContext } from '../context/BaseProvider';
import RefSection from '../components/RefSection';
import { useSettingsStats } from '../hooks/useState';

export default function Home() {
    const { created } = useContext(BaseContext);
    const settingStats = useSettingsStats(1);

  return (
    <>
      <Header />
      <div className='container-fluid'>
        <HeadSection settingStats={settingStats} />
        {created ? (
          <CreatedSection/>
        ) : (
          <CreateSection/>
        )}
        <DetailsSection settingStats={settingStats}/>
        <div className='row bg-card py-5 d-flex justify-content-around mx-0'>
          <LastTokens />
          <LastReferral />
        </div>
        <RefSection/>
      </div>

    </>
  )
}
