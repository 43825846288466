import { createContext, useState, useEffect } from 'react';
import { api } from '../services/api';



export const BaseContext = createContext();

export const BaseProvider = ({ children }) => {
    const [sidebar, setSidebar] = useState(false);
    const [createType, setCreateType] = useState(0);
    const [created, setCreated] = useState(false);
    const [name, setName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [decimals, setDecimals] = useState(18);
    const [supply, setSupply] = useState('');
    const [burn, setBurn] = useState(false);
    const [mint, setMint] = useState(false);
    const [taxFees, setTaxFees] = useState(true);
    // const [mwallet, setMwallet] = useState(false);
    const [buytax, setBuytax] = useState('');
    const [selltax, setSelltax] = useState('');
    const [sameOwner, setSameOwner] = useState(true);
    const [mwalletaddress, setMwalletaddress] = useState('');
    const [presale, setPresale] = useState(true);
    const [airdrop, setAirdrop] = useState(true);
    const [tokenAddress, setTokenAddress] = useState('');
    const [createHash, setCreateHash] = useState('');
    const [insertId,setInsertId] = useState(0);
    const [tokenData,setTokenData] = useState([]);
    const [isv3,setIsv3] = useState(false);
    const [poolfees,setPoolfees] = useState(0.30);


    useEffect(() => {
        if (insertId) {
            let formData = new FormData();
            formData.append("request_type", 'get_recent_token');
            formData.append("id", insertId);

            let interval = setInterval(() => {
            api.post(`/`, formData).then((response) => {
                if (response.data && response.data.status === 'success') {
                    console.log(response.data.data);
                    setTokenData(response.data.data)
                }
            });
            } , 10000);

            return () => clearInterval(interval);
        }
    }, [insertId,created]);




    return (
        <BaseContext.Provider
            value={{
                sidebar,
                setSidebar,
                createType,
                setCreateType,
                name,
                setName,
                symbol,
                setSymbol,
                decimals,
                setDecimals,
                supply,
                setSupply,
                burn,
                setBurn,
                mint,
                setMint,
                created,
                setCreated,
                taxFees,
                setTaxFees,
                buytax,
                setBuytax,
                selltax,
                setSelltax,
                sameOwner,
                setSameOwner,
                mwalletaddress,
                setMwalletaddress,
                presale,
                setPresale,
                airdrop,
                setAirdrop,
                tokenAddress,
                setTokenAddress,
                createHash,
                setCreateHash,
                insertId,
                setInsertId,
                tokenData,
                setTokenData,
                isv3,
                setIsv3,
                poolfees,
                setPoolfees
            }}
        >{children}</BaseContext.Provider>
    )
}