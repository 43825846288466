import { useEffect, useState } from "react"
import servicesAbi from '../json/services.json';
import multicallAbi from '../json/multicall.json';
import multisenderAbi from '../json/multisender.json';
import { toast } from "react-toastify";
import { DEFAULT_CHAIN, contract, SUPPORTED_CHAIN, getWeb3 } from "../helper/constant";
import { getMultiCall } from "../helper/contractHelper";
import { useAccount, useNetwork } from "wagmi";
import { api } from "../services/api";


export const useCommonStats = (updater) => {
    const { address } = useAccount()
    const { chain } = useNetwork();

    let currentChain = chain && chain.id ? SUPPORTED_CHAIN.includes(chain.id) ? chain.id : DEFAULT_CHAIN : DEFAULT_CHAIN;
    let web3 = getWeb3(currentChain);

    const [stats, setStats] = useState({
        basicFees: 0,
        standardFees: 0,
        premiumFees: 0,
        ethBalance: 0
    });

    let servicesContract = new web3.eth.Contract(servicesAbi, contract[currentChain].MASTER_ADDRESS);
    let multicallContract = new web3.eth.Contract(multicallAbi, contract[currentChain].MULTICALL_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall(
                    address ? [
                        servicesContract.methods.servicesFees('basic'), //0
                        servicesContract.methods.servicesFees('standard'), //1
                        servicesContract.methods.servicesFees('premium'), //2
                        multicallContract.methods.getEthBalance(address) //3
                    ] : [
                        servicesContract.methods.servicesFees('basic'), //0
                        servicesContract.methods.servicesFees('standard'), //1
                        servicesContract.methods.servicesFees('premium') //2
                    ], currentChain);

                setStats({
                    basicFees: data[0] / Math.pow(10, 18),
                    standardFees: data[1] / Math.pow(10, 18),
                    premiumFees: data[2] / Math.pow(10, 18),
                    ethBalance: data[3] ? data[3] / Math.pow(10, 18) : 0
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater, chain, address]);

    return stats;
}

export const useRefStats = (updater) => {
    const { chain } = useNetwork();

    let currentChain = chain && chain.id ? SUPPORTED_CHAIN.includes(chain.id) ? chain.id : DEFAULT_CHAIN : DEFAULT_CHAIN;
    let web3 = getWeb3(currentChain);

    const [stats, setStats] = useState({
        levelFees: 0,
        mlevelFees: 0
    });

    let servicesContract = new web3.eth.Contract(servicesAbi, contract[currentChain].MASTER_ADDRESS);
    let multisenderContract = new web3.eth.Contract(multisenderAbi, contract[currentChain].MULTISENDER_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    servicesContract.methods.levelRate(), //0
                    multisenderContract.methods.levelRate() //1

                ], currentChain);

                setStats({
                    levelFees: parseInt(data[0]) / 100,
                    mlevelFees: parseInt(data[1]) / 100,
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        fetch();

        // eslint-disable-next-line
    }, [updater, chain]);

    return stats;
}


export const useSettingsStats = (updater) => {
    const [stats, setStats] = useState({
        document_guide_token_create: '#sec',
        video_guide_token_create: '#sec',
        document_guide_multisender: '#sec',
        video_guide_multisender: '#sec',
        basic_this_function: '#sec',
        standard_this_function: '#sec',
        premium_this_function: '#sec',
        twitter: '#sec',
        telegram: '#sec',
        youtube: '#sec',
        create_token_youtube_video_id: '#sec',
        multisender_youtube_video_id: '#sec',
        download_wallet_link: '#sec'
    });

    useEffect(() => {
        const fetch = async () => {
            try {
                let formData = new FormData();
                formData.append("request_type", 'get_settings');
                let data = await api.post(`/`, formData).then((response) => {
                    if (response && response.data && response.data.data && response.data.status === "success") {
                        return response.data.data
                    }
                    return false;
                })
                    .catch((err) => {
                        console.log(err);
                        return false
                    });
                if (data) setStats(data);

            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}
