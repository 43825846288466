import React, { useEffect, useState } from 'react';
import { api } from '../services/api';
import { DEFAULT_CHAIN, contract } from '../helper/constant';

export default function LastTokens() {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        async function fetch() {
            let formData = new FormData();
            formData.append("request_type", 'get_history');
            formData.append("sub_type", 'transactions');
            api.post(`/`, formData).then((response) => {
                if (response && response.data && response.data.data && response.data.status === "success" && response.data.data.transaction_data) {
                    setTransactions(response.data.data.transaction_data)
                }
                else {
                    console.log(response.data.message);
                }
            });
        }

        fetch();
    }, []);



    return (
        <div className='col-12 col-md-5 py-3 px-3 border-radius-2xl bg-body-card'>
            <h5>Last Genrated Tokens</h5>
            <div className='border-dark px-4 mt-4'>
                {transactions && transactions.length > 0 ? (
                    transactions.map((rdata, k) => {
                        return (
                            <div className={`row d-flex align-items-center py-2 ${parseInt(k + 1) < parseInt(transactions.length) ? 'border-bottom' : ''}`} key={k}>
                                <div className='col-10'>
                                    <img src={contract[rdata.chain ? rdata.chain : DEFAULT_CHAIN].img} alt='binanceImg' height="30px" width="30px" />
                                    <span className='mx-2 text-sm'>{rdata.name ? rdata.name : ' - '}
                                       {" "} <span class="center-block text-sm">({rdata.symbol ? rdata.symbol : ' - '})</span>
                                    </span>
                                </div>
                                <div className='col-2'>
                                    <a target='_blank' href={`${contract[rdata.chain ? rdata.chain : DEFAULT_CHAIN].EXPLORE}token/${rdata.token_address ? rdata.token_address : ''}`} className='btn btn-sm btn-transparent mb-0 me-1'>
                                        View
                                    </a>
                                </div>
                            </div>
                        )
                    })

                ) : (
                    <div className='row d-flex pl-4 align-items-center py-2 border-bottom text-center'>
                        <div className='col-12 d-flex justify-content-center'>
                            No record Found
                        </div>

                    </div>
                )}
            </div>

        </div>
    )
}
