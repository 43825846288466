import React from 'react';
import CodeMirror from "@uiw/react-codemirror";
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressValidation, fetchStep1InvalidValidation, updateInvalidAddress, updateStep1ErrorModal } from '../../redux/slices/MultisenderSlice';
import { useNetwork } from 'wagmi';
import { DEFAULT_CHAIN, contract } from '../../helper/constant';


export default function Step1InvalidModal({ show }) {
    const dispatch = useDispatch();
    const invalidAddress = useSelector(state => state.multisender.invalidAddress);
    const { chain } = useNetwork()

    return (
        <Modal show={show} onHide={() => dispatch(updateStep1ErrorModal())} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title className='example-title'>Invalid Addresses</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <React.Fragment>
                    <CodeMirror
                        height="200px"
                        theme={'dark'}
                        placeholder="Insert address and amount,separate with comma"
                        style={{
                            border: "1px solid #444444",
                        }}
                        onChange={(e) => dispatch(updateInvalidAddress(e))}
                        value={invalidAddress}
                    />

                </React.Fragment>
                <button type="button" onClick={() => dispatch(fetchStep1InvalidValidation({
                    chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN
                }))} class="btn text-dark bg-warning w-full mt-3">
                    Continue
                </button>

            </Modal.Body>
        </Modal>
    )
}
