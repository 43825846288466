import React, { useContext } from 'react';
import docsImg from '../images/document.png';
import vdoImg from '../images/vdo.png';
import guideImg from '../images/guide-icon.png';
import { BaseContext } from '../context/BaseProvider';
import { HEAD_TEST_TEXT_CREATE, HEAD_TEXT_CREATE, IS_MAINNET, MAINNET_LINK, TESTNET_LINK } from '../helper/constant';

export default function HeadSection({settingStats}) {
    const { createType, setCreateType } = useContext(BaseContext);
    return (
        <div className='row d-flex align-items-center justify-content-center py-4'>

            <div className='col-12 col-md-7 col-xl-5 text-center'>
                <h3 className='text-white mt-2 mt-md-0 mb-md-0 text-bold text-uppercase'>Create your own token easy</h3>
                <p className='text-success text-bold mb-1 mb-md-0 text-lg'>All Smart Contracts have been audit</p>
                <p className='text-white text-bold mb-1 mb-md-2 text-lg'>{IS_MAINNET ? HEAD_TEST_TEXT_CREATE : HEAD_TEXT_CREATE} <a href={`${IS_MAINNET ? TESTNET_LINK : MAINNET_LINK}`} target='_blank' className='text-warning'>click here</a></p>
                <div className='d-flex justify-content-center justify-content-xl-around mt-2 mt-md-4'>
                    <div className='d-flex flex-column'>
                        <button onClick={() => setCreateType(0)} className={`btn btn-sm btn-plan mb-0 me-1 text-dark ${createType === 0 ? 'bg-warning' : 'bg-secondary'}`}>
                            Basic Token
                        </button>
                        <div className='mt-1'>
                            <a href={settingStats && settingStats.basic_this_function ? settingStats.basic_this_function : '#sec' } target='_blank'>
                                <img src={guideImg} alt="guideImg" height="15px" width="15px" />
                                <span className='text-function'> This Function?</span>
                            </a>

                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <button onClick={() => setCreateType(1)} className={`btn btn-sm btn-plan mb-0 me-1 text-dark ${createType === 1 ? 'bg-warning' : 'bg-secondary'}`}>
                            Standard Token
                        </button>
                        <div className='mt-1'>
                            <a href={settingStats && settingStats.standard_this_function ? settingStats.standard_this_function : '#sec' } target='_blank'>
                                <img src={guideImg} alt="guideImg" height="15px" width="15px" />
                                <span className='text-function'> This Function?</span>
                            </a>

                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <button onClick={() => setCreateType(2)} className={`btn btn-sm btn-plan mb-0 me-1 text-dark ${createType === 2 ? 'bg-warning' : 'bg-secondary'}`}>
                            Premium Token
                        </button>
                        <div className='mt-1'>
                            <a href={settingStats && settingStats.premium_this_function  ? settingStats.premium_this_function     : '#sec' } target='_blank'>
                                <img src={guideImg} alt="guideImg" height="15px" width="15px" />
                                <span className='text-function'> This Function?</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}
