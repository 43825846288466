import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ethereumClient, wagmiConfig } from './helper/wagmi';
import { Web3Modal } from '@web3modal/react';
import { WagmiConfig } from 'wagmi'
import { BaseProvider } from './context/BaseProvider';
import store from './redux/store';
import { Provider } from 'react-redux';
import { PROJECT_ID } from './helper/constant';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BaseProvider>
        <WagmiConfig config={wagmiConfig}>
          <App />
        </WagmiConfig>
        <Web3Modal projectId={PROJECT_ID} ethereumClient={ethereumClient} themeVariables={{
        '--w3m-accent-color': '#33363f',
        '--w3m-background-color': '#33363f',
      }} />
      </BaseProvider>
    </Provider>
  </React.StrictMode>
);

