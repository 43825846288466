import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PER_TX, ZERO_ADDRESS, contract, getWeb3 } from '../../helper/constant';
import { getMultiCall, getWeb3Contract } from '../../helper/contractHelper';
import tokenAbi from '../../json/token.json';
import nftAbi from '../../json/nft.json';

import multisenderAbi from '../../json/multisender.json'
import { ethers } from 'ethers';






const initalState = {
  totalStep: 4,
  step: 1,
  tokenAddress: '',
  tokenLoader: false,
  addressLoader: false,
  isDrop: false,
  tokenError: '',
  tokenType: 1, // 1-ETH,2-TOKEN,3-NFT
  ethInfo: {
    decimals: 18,
    balance: 0,
    symbol: ''
  },
  tokenInfo: {
    name: '',
    decimals: '',
    symbol: '',
    balance: 0,
    allowance: 0
  },
  nftInfo: {
    name: '',
    symbol: '',
    balance: 0,
    allowance: false
  },
  listAddress: '',
  invalidAddress: '',
  listAddressError: '',
  addressArray: [],
  amountArray: [],
  step1ErrorModal: false,
  step1Validation: false,
  totalTokenSent: 0,
  numberofaddress: 0,
  numberoftransaction: 0,
  cost: 0,
  step2validation: false,
  step2ErrorMessage: '',
  step3ErrorMessage: '',
  step3validation: false,
  //last step
  chunkAddress: [],
  chaunkAmounts: [],
  txhash: [],
}


export const fetchTokenInfo = createAsyncThunk('multisender/fetchTokenInfo', async ({ tokenAddress, chainId, address }) => {
  let ethData = {
    decimals: 18,
    balance: 0,
    symbol: contract[chainId].symbol
  };
  try {
    if (address) {
      let web3 = getWeb3(chainId);
      let ethBalance = web3.utils.fromWei(await web3.eth.getBalance(address));
      ethData.balance = ethBalance;

      if (tokenAddress) {

        let checkAddress = await web3.utils.isAddress(tokenAddress);

        if (checkAddress) {
          let checkSumaddress = await web3.utils.toChecksumAddress(tokenAddress);
          let isCode = await web3.eth.getCode(tokenAddress);
          if (checkSumaddress && isCode !== '0x') {
            try {
              let nftContract = getWeb3Contract(nftAbi, tokenAddress, chainId);
              let data = await getMultiCall([
                nftContract.methods.name(), //0
                nftContract.methods.symbol(), //1
                nftContract.methods.isApprovedForAll(address, contract[chainId].MULTISENDER_ADDRESS), //2
                nftContract.methods.balanceOf(address) //3
              ], chainId);

              let setData = setTokenInfoUsingAddress(3, tokenAddress, data, ethData);

              return setData;

            }
            catch (err) {
              console.log(err);
              try {
                let tokenContract = getWeb3Contract(tokenAbi, tokenAddress, chainId);

                let data = await getMultiCall([
                  tokenContract.methods.name(), //0
                  tokenContract.methods.symbol(), //1
                  tokenContract.methods.decimals(), //2
                  tokenContract.methods.balanceOf(address), //3
                  tokenContract.methods.allowance(address, contract[chainId].MULTISENDER_ADDRESS), //4
                ], chainId);



                let setData = setTokenInfoUsingAddress(2, tokenAddress, data, ethData);
                return setData;
              }
              catch (err) {
                let errMsg = 'Please enter valid address'
                let setData = setTokenInfoUsingAddress(1, tokenAddress, {}, ethData, errMsg);
                return setData;
              }
            }
          }
          let errMsg = 'Please enter valid address'
          let setData = setTokenInfoUsingAddress(1, tokenAddress, {}, ethData, errMsg);
          return setData;
        }
      }

      let setData = setTokenInfoUsingAddress(1, tokenAddress, {}, ethData);
      return setData;
    }
    else {
      let tokenError = 'please connect wallet'
      let setData = setTokenInfoUsingAddress(1, tokenAddress, {}, ethData, tokenError);
      return setData;
    }
  }
  catch (err) {
    console.log(err.message);
    let tokenError = err.reason ? err.reason : err.message
    let setData = setTokenInfoUsingAddress(1, tokenAddress, {}, ethData, tokenError);
    return setData;
  }
})

export const fetchAddressValidation = createAsyncThunk('multisender/fetchAddressValidation', async ({ data }) => {
  try {
    if (data) {
      let inputAddressArray = data.split("\n");
      let response = await Promise.all(inputAddressArray.map(async (items) => {
        let sAddress = items.trim().split(',')[0].toString().toLowerCase();
        let sAmount = items.trim().split(',')[1];

        if (sAddress.toString().toLowerCase() !== 'address' && sAmount !== 'amount') {
          return items
        }
        return false;
      }))

      response = response.filter((item) => item)

      return {
        listAddress: response.join('\n'),
        isDrop: false
      }
    }
    else {
      return {
        listAddress: '',
        isDrop: false
      }
    }
  }
  catch (err) {
    console.log(err.message)
    return {
      listAddress: '',
      isDrop: false
    }
  }
})

export const fetchStep1Validation = createAsyncThunk('multisender/fetchStep1Validation', async ({ chainId, address }, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const data = currentState.multisender.listAddress;

  try {
    let web3 = getWeb3(chainId);
    let ethBalance = web3.utils.fromWei(await web3.eth.getBalance(address));
    if (data) {

      let inputAddressArray = data.split("\n");

      let invalid_array = [];
      let totalTokenSent = 0;
      let addressArray = [];
      let amountArray = [];
      let numberofaddress = 0
      let response = await Promise.all(inputAddressArray.map(async (items) => {
        let sAddress = items.trim().split(',')[0].toString().toLowerCase();
        let sAmount = items.trim().split(',')[1];

        if (sAddress.toString().toLowerCase() !== 'address' && sAddress !== '' && sAmount !== '' && /^[0-9]+(\.[0-9]+)?$/.test(sAmount)) {
          let checkAddress = await web3.utils.isAddress(sAddress);
          if (checkAddress) {
            totalTokenSent += parseFloat(parseFloat(sAmount).toFixed(5))
            numberofaddress += 1;
            addressArray.push(sAddress);
            (currentState.multisender.tokenType === 2 || currentState.multisender.tokenType === 1) && amountArray.push(ethers.utils.parseUnits(sAmount.toString(), currentState.multisender.tokenAddress ? currentState.multisender.tokenInfo.decimals : 18));
            currentState.multisender.tokenType === 3 && amountArray.push(sAmount);
            return items;
          }
        }
        if (sAddress.toString().toLowerCase() !== 'address') {
          invalid_array.push(items)
        }
        return false;
      }))

      response = response.filter((item) => item)

      return {
        listAddress: response.join('\n'),
        invalidAddress: invalid_array.join('\n'),
        addressArray,
        amountArray,
        isDrop: false,
        step1Validation: false,
        step1ErrorModal: invalid_array.length > 0 ? true : false,
        step: invalid_array.length > 0 || !response ? 1 : 2,
        listAddressError: '',
        totalTokenSent,
        ethInfo: {
          decimals: 18,
          balance: ethBalance,
          symbol: contract[chainId].symbol
        },
        numberofaddress
      }
    }
    else {
      return {
        listAddress: data,
        invalidAddress: '',
        isDrop: false,
        step1Validation: false,
        step1ErrorModal: false,
        totalTokenSent: 0,
        listAddressError: 'Please enter address and amount..',
        ethInfo: {
          decimals: 18,
          balance: ethBalance,
          symbol: contract[chainId].symbol
        },
      }
    }
  }
  catch (err) {
    return {
      listAddress: data,
      invalidAddress: '',
      isDrop: false,
      totalTokenSent: 0,
      step1Validation: false,
      step1ErrorModal: false,
      listAddressError: err.message,
      ethInfo: {
        decimals: 18,
        balance: ethBalance,
        symbol: contract[chainId].symbol
      }
    }
  }
})

export const fetchStep1InvalidValidation = createAsyncThunk('multisender/fetchStep1InvalidValidation', async ({ chainId }, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const data = currentState.multisender.invalidAddress;

  try {

    if (data) {
      let web3 = getWeb3(chainId);
      let inputAddressArray = data.split("\n");

      let invalid_array = [];

      let response = await Promise.all(inputAddressArray.map(async (items) => {
        let sAddress = items.trim().split(',')[0].toString().toLowerCase();
        let sAmount = items.trim().split(',')[1];

        if (sAddress.toString().toLowerCase() !== 'address' && sAddress !== '' && sAmount !== '' && /^[0-9]+(\.[0-9]+)?$/.test(sAmount)) {
          let checkAddress = await web3.utils.isAddress(sAddress);
          if (checkAddress) {
            return items
          }
        }
        if (sAddress.toString().toLowerCase() !== 'address') {
          invalid_array.push(items)
        }
        return false;
      }))

      response = response.filter((item) => item)

      return {
        listAddress: currentState.multisender.listAddress + '\n' + response.join('\n'),
        invalidAddress: invalid_array.join('\n'),
        isDrop: false,
        step1Validation: false,
        step1ErrorModal: false,
        listAddressError: ''
      }
    }
    else {
      return {
        listAddress: currentState.multisender.listAddress,
        invalidAddress: '',
        isDrop: false,
        step1Validation: false,
        step1ErrorModal: false,
        listAddressError: ''
      }
    }
  }
  catch (err) {
    return {
      listAddress: currentState.multisender.listAddress,
      invalidAddress: '',
      isDrop: false,
      step1Validation: false,
      step1ErrorModal: false,
      listAddressError: err.message
    }
  }
})

export const fetchStep2Validation = createAsyncThunk('multisender/fetchStep2Validation', async ({ chainId, address }, thunkAPI) => {
  try {
    let currentState = thunkAPI.getState();
    currentState = currentState.multisender;

    if (parseFloat(currentState.tokenInfo.allowance) < parseFloat(currentState.tokenInfo.totalTokenSent)) {
      return {
        allowance: 0,
        step2Validation: false
      }
    }

    if (parseFloat(currentState.tokenInfo.balance) < parseFloat(currentState.tokenInfo.totalTokenSent)) {
      return {
        step2ErrorMessage: 'you not have enough balance',
        step2Validation: false
      }
    }

    let addressChunk = chunkArray(currentState.addressArray, PER_TX);
    let amountChunk = chunkArray(currentState.amountArray, PER_TX);
    let countGasCost = await getGasCost(chainId, addressChunk[0], amountChunk[0], currentState.tokenAddress, address, currentState.totalTokenSent, currentState.tokenType)
    if (countGasCost && countGasCost.error === 'OK') {
      return {
        step2ErrorMessage: '',
        step: 3,
        numberoftransaction: Math.ceil(currentState.numberofaddress / PER_TX),
        cost: parseFloat(Math.ceil(currentState.numberofaddress / PER_TX) * parseFloat(countGasCost.cost)) + parseFloat(Math.ceil(currentState.numberofaddress / PER_TX) * parseFloat(countGasCost.txfees)),
        step2Validation: false
      }
    }
    else {
      return {
        step2ErrorMessage: countGasCost.error,
        step: 2,
        cost: 0,
        step2Validation: false
      }
    }
  }
  catch (err) {
    return {
      step2ErrorMessage: err.message,
      step: 2,
      cost: 0,
      step2Validation: false
    }
  }
})

export const fetchStep3Validation = createAsyncThunk('multisender/fetchStep3Validation', async ({ }, thunkAPI) => {
  try {

    let currentState = thunkAPI.getState();
    currentState = currentState.multisender;
    let addressChunk = chunkArray(currentState.addressArray, PER_TX);
    let amountChunk = chunkArray(currentState.amountArray, PER_TX);
    if (addressChunk && amountChunk && addressChunk.length === amountChunk.length) {
      return {
        chunkAddress: addressChunk,
        chaunkAmounts: amountChunk,
        step3validation: false,
        step3ErrorMessage: '',
        step: 4
      }
    }
    else {
      console.log('issue');
      return {
        step3ErrorMessage: 'something went wrong',
        step3validation: false
      }
    }
  }
  catch (err) {
    console.log(err.message);
  }
})

async function getGasCost(chainId, address, amounts, tokenAddress, userAddress, ethvaluesend, tokenType) {
  try {
    let web3 = getWeb3(chainId);
    let multisenderContract = getWeb3Contract(multisenderAbi, contract[chainId].MULTISENDER_ADDRESS, chainId);
    let txfees = await multisenderContract.methods.fee().call();
    let ethfees = 0;

    if (!tokenAddress) {
      ethfees = parseFloat(txfees / Math.pow(10, 18))
      ethfees = parseFloat(parseFloat(ethfees) + parseFloat(ethvaluesend))
      ethfees = ethers.utils.parseUnits(ethfees.toString(), 18)
    }

    tokenAddress = tokenAddress ? tokenAddress : ZERO_ADDRESS;
    let gasEstimate;
    if (tokenType === 3) {
      gasEstimate = await multisenderContract.methods.transferNft(tokenAddress, address, amounts, ZERO_ADDRESS).estimateGas({
        from: userAddress,
        value: txfees
      });
    }
    else {
      gasEstimate = await multisenderContract.methods.transferToken(tokenAddress, address, amounts, ZERO_ADDRESS).estimateGas({
        from: userAddress,
        value: tokenAddress === ZERO_ADDRESS ? ethfees : txfees
      });
    }

    console.log(gasEstimate);

    txfees = parseFloat(txfees / Math.pow(10, 18))
    const gasPrice = await web3.eth.getGasPrice();
    const gasCostWei = gasEstimate.toString() * gasPrice;
    const gasCostEther = web3.utils.fromWei(gasCostWei.toString(), 'ether');

    return {
      cost: gasCostEther,
      txfees,
      error: 'OK',
    }

  }
  catch (err) {
    return {
      cost: 0,
      error: err.message,
    }
  }
}

function chunkArray(array, chunkSize) {
  const resultArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    resultArray.push(array.slice(i, i + chunkSize));
  }
  return resultArray;
}

function setTokenInfoUsingAddress(type = 1, tokenAddress = '', info = {}, ethInfo = {}, tokenError = '') {
  if (type === 3) {
    return {
      tokenType: type,
      tokenAddress,
      tokenInfo: {
        name: '',
        symbol: '',
        decimals: 0,
        balance: 0,
        allowance: 0
      },
      ethInfo: ethInfo,
      nftInfo: {
        name: info[0],
        symbol: info[1],
        allowance: info[2],
        balance: info[3]
      },
      tokenError,
      tokenLoader: false
    }
  }
  else if (type === 2) {
    return {
      tokenType: type,
      tokenAddress,
      tokenInfo: {
        name: info[0],
        symbol: info[1],
        decimals: info[2],
        balance: info[3] / Math.pow(10, info[2]),
        allowance: info[4] / Math.pow(10, info[2])
      },
      ethInfo: ethInfo,
      nftInfo: {
        name: '',
        symbol: '',
        allowance: false,
        balance: 0,
      },
      tokenError,
      tokenLoader: false
    }
  }
  else {
    return {
      tokenType: type,
      tokenAddress,
      tokenInfo: {
        name: '',
        symbol: '',
        decimals: 0,
        balance: 0,
        allowance: 0
      },
      ethInfo: ethInfo,
      nftInfo: {
        name: '',
        symbol: '',
        balance: 0,
        allowance: false
      },
      tokenError,
      tokenLoader: false
    }
  }
}


export const multisenderSlice = createSlice({
  name: 'multisender',
  initialState: initalState,
  reducers: {
    nextStep(state) {
      state.step = state.step + 1 > 4 ? 4 : state.step + 1;
    },
    updateLoader(state) {
      state.tokenLoader = state;
    },
    updateIsDrop(state) {
      state.isDrop = !state.isDrop
    },
    updateStep1Validation(state) {
      state.step1Validation = true;
    },
    updateStep1ErrorModal(state) {
      state.step1ErrorModal = false;
    },
    updateInvalidAddress(state, action) {
      state.invalidAddress = action.payload
    },
    updateAllowance(state, action) {
      state.tokenInfo.allowance = action.payload;
      state.nftInfo.allowance = true;
    },
    addTxHash(state, action) {
      const { id, hash, status, error } = action.payload;
      state.txhash[id] = { hash, status, error };
    },
    updateTxHash(state, action) {
      const { id, hash, status, error } = action.payload;
      if (state.txhash[id]) {
        state.txhash[id] = { hash, status, error };
      }
    }
  },
  extraReducers: (builder) => {
    //fetchTokenInfo
    builder.addCase(fetchTokenInfo.pending, (state) => {
      console.log('fetchTokenInfo pending....');
      return { ...state, tokenLoader: true }
    })
    builder.addCase(fetchTokenInfo.fulfilled, (state, action) => {
      console.log(action.payload);
      return { ...state, ...action.payload, tokenLoader: false }
    })
    builder.addCase(fetchTokenInfo.rejected, (state) => {
      console.log('fetchTokenInfo rejected...')
      return { ...state, tokenLoader: false }
    })

    //fetchAddressValidation

    builder.addCase(fetchAddressValidation.pending, (state) => {
      console.log('fetchAddressValidation pending....');
      return { ...state, addressLoader: true }
    })
    builder.addCase(fetchAddressValidation.fulfilled, (state, action) => {
      console.log('fetchAddressValidation fulfilled....');
      return { ...state, ...action.payload, addressLoader: false }
    })
    builder.addCase(fetchAddressValidation.rejected, (state) => {
      console.log('fetchAddressValidation rejected...')
      return { ...state, addressLoader: false }
    })


    //fetchStep1Validation

    builder.addCase(fetchStep1Validation.pending, (state) => {
      console.log('fetchStep1Validation pending....');
      return { ...state, step1Validation: true }
    })
    builder.addCase(fetchStep1Validation.fulfilled, (state, action) => {
      console.log('fetchStep1Validation fulfilled....');
      return { ...state, ...action.payload }
    })
    builder.addCase(fetchStep1Validation.rejected, (state) => {
      return { ...state, step1Validation: false }
    })

    //fetchStep1InvalidValidation

    builder.addCase(fetchStep1InvalidValidation.pending, (state) => {
      console.log('fetchStep1InvalidValidation pending....');
      return { ...state, step1Validation: true }
    })
    builder.addCase(fetchStep1InvalidValidation.fulfilled, (state, action) => {
      console.log('fetchStep1InvalidValidation fulfilled....');
      return { ...state, ...action.payload }
    })
    builder.addCase(fetchStep1InvalidValidation.rejected, (state) => {
      console.log('fetchStep1InvalidValidation rejected....');
      return { ...state, step1Validation: false }
    })


    //fetchStep2Validation

    builder.addCase(fetchStep2Validation.pending, (state) => {
      console.log('fetchStep2Validation pending....');
      return { ...state, step2Validation: true }
    })
    builder.addCase(fetchStep2Validation.fulfilled, (state, action) => {
      console.log('fetchStep2Validation fulfilled....');
      return { ...state, ...action.payload }
    })
    builder.addCase(fetchStep2Validation.rejected, (state) => {
      console.log('fetchStep2Validation rejected....');
      return { ...state, step2Validation: false }
    })

    //fetchStep3Validation

    builder.addCase(fetchStep3Validation.pending, (state) => {
      console.log('fetchStep3Validation pending....');
      return { ...state, step3Validation: true }
    })
    builder.addCase(fetchStep3Validation.fulfilled, (state, action) => {
      console.log('fetchStep3Validation fulfilled....');
      return { ...state, ...action.payload }
    })
    builder.addCase(fetchStep3Validation.rejected, (state, action) => {
      console.log(action.error.message)
      console.log('fetchStep3Validation rejected....');
      return { ...state, step3Validation: false }
    })


  }
});


export const { nextStep, updateIsDrop, updateLoader, updateStep1Validation, updateStep1ErrorModal, updateInvalidAddress, updateAllowance, addTxHash, updateTxHash } = multisenderSlice.actions;
export default multisenderSlice.reducer;